import React, { lazy, useEffect, useState } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { useFetchProfile } from 'state/profile/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import EasterEgg from './components/EasterEgg'
import history from './routerHistory'
// Views included in the main bundle
import Pools from './views/Pools'
import Swap from './views/Swap'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './views/AddLiquidity/redirects'
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'
import { RedirectPathToSwapOnly, RedirectToSwap } from './views/Swap/redirects'
import GlobalCheckClaimStatus from './views/Collectibles/components/GlobalCheckClaimStatus'

import binanceLogo from './assets/images/binance-logo.png'
import ethereumLogo from './assets/images/ethereum-logo.png'
import maticImage from './assets/images/MATIC.png'
import logoImage from './assets/images/logoImage.png'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const FarmAuction = lazy(() => import('./views/FarmAuction'))
const Lottery = lazy(() => import('./views/Lottery'))
const Ifos = lazy(() => import('./views/Ifos'))
const NotFound = lazy(() => import('./views/NotFound'))
const Collectibles = lazy(() => import('./views/Collectibles'))
const Teams = lazy(() => import('./views/Teams'))
const Team = lazy(() => import('./views/Teams/Team'))
const Profile = lazy(() => import('./views/Profile'))
const TradingCompetition = lazy(() => import('./views/TradingCompetition'))
const Predictions = lazy(() => import('./views/Predictions'))
const PredictionsLeaderboard = lazy(() => import('./views/Predictions/Leaderboard'))
const Voting = lazy(() => import('./views/Voting'))
const Proposal = lazy(() => import('./views/Voting/Proposal'))
const CreateProposal = lazy(() => import('./views/Voting/CreateProposal'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const Info = lazy(() => import('./views/Info'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: #82ff00;
`
const LogoImage = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 15px;
`
const TokenPriceArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 50px;
  @media (max-width: 600px) {
    display: none;
  }
`
const TokenPriceItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const TokenImage = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 20px;
  margin-right: 5px;
`
const TokenPrice = styled.p`
  color: rgb(13, 11, 133);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-right: 8px;
`

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  usePollCoreFarmData()

  const [bnbPriceUsd, setBnbPriceUsd] = useState(0)
  const [sombraPriceUsd, setSombraPriceUsd] = useState(0)
  const [ethereumPriceUsd, setEthereumPriceUsd] = useState(0)

  useEffect(() => {
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd')
      .then((response) => response.json())
      .then((data) => {
        const bscUsd = data.binancecoin.usd
        setBnbPriceUsd(parseFloat(bscUsd))
      })
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd')
      .then((response) => response.json())
      .then((data) => {
        const ethereumUsd = data.ethereum.usd
        setEthereumPriceUsd(parseFloat(ethereumUsd))
      })
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=sombra-network&vs_currencies=usd')
      .then((response) => response.json())
      .then((data) => {
        const sombraUsd = data['sombra-network'].usd
        setSombraPriceUsd(parseFloat(sombraUsd))
      })
  }, [])

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <GlobalCheckClaimStatus excludeLocations={['/collectibles']} />
      <Navbar>
        <LogoImage src={logoImage} alt="" />
        <TokenPriceArea>
          <TokenPriceItem>
            <TokenImage src={ethereumLogo} alt="" />
            <TokenPrice>{`$${ethereumPriceUsd.toFixed(2)}`}</TokenPrice>
          </TokenPriceItem>
          <TokenPriceItem>
            <TokenImage src={binanceLogo} alt="" />
            <TokenPrice>{`$${bnbPriceUsd.toFixed(2)}`}</TokenPrice>
          </TokenPriceItem>
          <TokenPriceItem>
            <TokenImage src={logoImage} alt="" />
            <TokenPrice>{`$${sombraPriceUsd.toFixed(2)}`}</TokenPrice>
          </TokenPriceItem>
        </TokenPriceArea>
      </Navbar>
      {/* <Menu> */}
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Switch>
          <Route path="/swap" exact>
            <Home />
          </Route>
          <Route exact path="/farms/auction">
            <FarmAuction />
          </Route>
          <Route path="/farms">
            <Farms />
          </Route>
          <Route path="/pools">
            <Pools />
          </Route>
          <Route path="/lottery">
            <Lottery />
          </Route>
          <Route path="/ifo">
            <Ifos />
          </Route>
          <Route path="/collectibles">
            <Collectibles />
          </Route>
          <Route exact path="/teams">
            <Teams />
          </Route>
          <Route path="/teams/:id">
            <Team />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/competition">
            <TradingCompetition />
          </Route>
          <Route exact path="/prediction">
            <Predictions />
          </Route>
          <Route path="/prediction/leaderboard">
            <PredictionsLeaderboard />
          </Route>
          <Route exact path="/voting">
            <Voting />
          </Route>
          <Route exact path="/voting/proposal/create">
            <CreateProposal />
          </Route>
          <Route path="/voting/proposal/:id">
            <Proposal />
          </Route>
          {/* Info pages */}
          <Route path="/info">
            <Info />
          </Route>

          {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
          <Route exact strict path="/" component={Swap} />
          <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
          <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
          <Route exact strict path="/find" component={PoolFinder} />
          <Route exact strict path="/liquidity" component={Liquidity} />
          <Route exact strict path="/create" component={RedirectToAddLiquidity} />
          <Route exact path="/add" component={AddLiquidity} />
          <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
          <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
          <Route exact path="/create" component={AddLiquidity} />
          <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
          <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
          <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
          <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

          {/* Redirect */}
          <Route path="/pool">
            <Redirect to="/liquidity" />
          </Route>
          <Route path="/staking">
            <Redirect to="/pools" />
          </Route>
          <Route path="/syrup">
            <Redirect to="/pools" />
          </Route>
          <Route path="/nft">
            <Redirect to="/collectibles" />
          </Route>

          {/* 404 */}
          <Route component={NotFound} />
        </Switch>
      </SuspenseWithChunkError>
      {/* </Menu> */}
      <EasterEgg iterations={2} />
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
